// React
import React, { useState } from 'react';

// Icons
import { FiEdit } from 'react-icons/fi';

// Utils
import getAssetStatus from '../../utils/getAssetStatus';

const getHealthClass = state => {
  if (state === 'ok') {
    return 'health-healthy';
  } else if (state === 'warning') {
    return 'health-unhealthy';
  }

  return 'health-down';
};

const AssetList = ({
  filteredAssets,
  setTargetDetails,
  toggleAssetRealTimeData,
  toggleAssetDetails,
}) => {
  const [hoveredItemId, setHoveredItemId] = useState(false);

  return (
    <div className="border rounded-lg grow px-2 mx-3 md:px-4 md:mx-8 overflow-auto text-sm md:text-base relative">
      <table className="table-auto w-full">
        <thead className="table-head">
          <tr>
            <th className="table-head-item"></th>
            <th className="table-head-item">Asset Name</th>
            <th className="table-head-item">BESS Serial Number</th>
            <th className="table-head-item text-center">Battery SOC</th>
            <th className="table-head-item text-center">Status</th>
            <th className="table-head-item">Site Description</th>
            <th className="table-head-item">Account Name</th>
            <th className="table-head-item">Asset Contact</th>
            <th className="table-head-item">Model Number</th>
            <th className="table-head-item">Gateway SN</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets.map(item => {
            return (
              <tr
                key={item.assetId}
                className="border-b cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();

                  setTargetDetails(item);
                  toggleAssetRealTimeData()
                }}
                onMouseEnter={() => setHoveredItemId(item.assetId)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                <td
                  className="pr-2 py-2 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();

                    setTargetDetails(item);
                    toggleAssetDetails();
                  }}
                >
                  <FiEdit
                    className={`inline-flex self-center ${hoveredItemId !== item.assetId && 'md:invisible'
                      }`}
                    size={20}
                  />
                </td>
                <td className="pr-2 py-2">{item.bessName}</td>
                <td className="pr-2 py-2 relative">
                  <span type="button" role="button">
                    {item.name}
                  </span>
                </td>
                <td className="pr-2 py-2 text-center">
                  {item.batterySOC?.toFixed(2)}
                </td>
                <td className="pr-2 py-2">
                  <div className="flex flex-row justify-center items-center align-middle whitespace-nowrap h-full">
                    <span
                      className={`health ${getHealthClass(
                        item.errorState
                      )} mr-2`}
                    ></span>
                    {getAssetStatus(item.errorState)}
                  </div>
                </td>
                <td className="pr-2 py-2">{item.siteDescription}</td>
                <td className="pr-2 py-2">{item.accountName}</td>
                <td className="pr-2 py-2">{`${item.assetContactFirstName} ${item.assetContactLastName}`}</td>
                <td className="pr-2 py-2">{item.modelNumber}</td>
                <td className="pr-2 py-2">{item.gatewaySerialNumber}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssetList;
